
import { defineComponent, ref,computed,reactive } from "vue";
//import { ErrorMessage, Field, Form } from "vee-validate";

import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {l} from '@/core/helpers/lang';
import {VueTelInput} from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import toast from '@/core/functions/toast';
import ApiService from "@/core/services/ApiService";
import {get_country_api_url} from "@/ayar";



export default defineComponent({
  name: "sign-up",
  components: {
    /*Field,
    Form,
    ErrorMessage,*/
    VueTelInput
  },
  data() {
    return {
      name:'',
      surname:'',
      sponsorID:'',
      phone: '',
      password: '',
      bindProps: {
        placeholder: l('admin:languages:langugaes434'),
        required: false,
        autocomplete: 'off',
        name: 'phone',
        autoFormat: true,
        customValidate: true,
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false,
          styleClasses: 'shadow-none'

        }
      },
    };
  },
    methods: {
    onInput(phone, phoneObject) {
      if (phoneObject) {
        if (phoneObject.formatted) {
          this.form.phone = phoneObject.country.dialCode + phoneObject.nationalNumber;
        }
      }
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const registerButton = ref<HTMLElement | null>(null);
    const form = reactive({
      phone: '',
      password: '',
      password_confirmation : '',
      name:'',
      surname:'',
      sponsored_by:'',
      uyelik : '',
      kvkk : '',
    });
    const parameters = computed(() => store.getters.getParameterList);
    const registration = Yup.object().shape({
      name: Yup.string().required().label("First Name"),
      surname: Yup.string().required().label("Second Name"),
      sponsored_by: Yup.string().min(9).required().label("Sponsor ID"),
      password: Yup.string().min(4).required().label("Password"),
      password_confirmation: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("password"), null], l('admin:transfer:Şifre onayı eşleşmiyor'))
        .label("Password Confirmation"),
    });



    const onSubmitRegister = (event,values) => {
      // Clear existing errors
      event.preventDefault();
      store.dispatch(Actions.PURGE_AUTH);

      // Activate indicator
      registerButton.value?.setAttribute("data-kt-indicator", "on");


      // Dummy delay
 
          setTimeout(() => {
              // Send login request
              store
                .dispatch(Actions.REGISTER, values)
                .then(() => {
                    Swal.fire({
                      text: l('admin:genel:success'),
                      icon: "success",
                      buttonsStyling: false,
                      confirmButtonText: l('admin:transfer:Devam Et'),
                      customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                      },
                    }).then(function () {
                      // Go to page after successfully login
                      router.push({ name: "ozet" });
                    });
                })
                .catch(() => {
                  toast(store.getters.getErrors, false);
                });

              registerButton.value?.removeAttribute("data-kt-indicator");
            }, 1000);
    
    };
    const defaultCountry = ref('tr');

    ApiService.get(get_country_api_url).then(function (payload) {
      defaultCountry.value = payload.data.country_code
    })

    return {
      registration,
      onSubmitRegister,
      registerButton,
      defaultCountry,
      l,
      parameters,
      form,
      generalSettings: computed(() => store.getters.getGeneralSettingsList),
    };
  },
});
